//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import TocButton from './TocButton';
import SettingsButton from './SettingsButton';
import SearchButton from './SearchButton';

export default {
  name: 'TopBar',
  components: {
    TocButton,
    SettingsButton,
    SearchButton,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    isInFullscreen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    /**
     * @public
     */
    focusOnTocButton() {
      this.$refs.tocButton.$el.focus();
    },
    /**
     * @public
     */
    focusOnSettingsButton() {
      this.$refs.settingsButton.$el.focus();
    },
    /**
     * @public
     */
    focusOnSearchButton() {
      this.$refs.searchButton.$el.focus();
    },
  },
  $trs: {
    toggleFullscreen: 'Toggle fullscreen',
  },
};

